import React from 'react';
import { useRouter } from 'next/router';
import { shape, arrayOf, string, bool } from 'prop-types';
import Head from 'next/head';

const Meta = ({ seo, metadata, updatedBy }) => {
  const router = useRouter();

  const { title, description, canonical, metaImage, noIndex, noFollow, schemaMarkup } = {
    ...seo,
  };

  let robotsContent;

  if (noIndex && noFollow) {
    robotsContent = 'noindex, nofollow';
  } else if (noIndex) {
    robotsContent = 'noindex';
  } else if (noFollow) {
    robotsContent = 'nofollow';
  } else {
    robotsContent = '';
  }

  return (
    <Head>
      {/* Page specific metadata */}
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:title" content={title} />
      {metaImage?.url && <meta name="twitter:image" content={metaImage.url} />}
      {metaImage?.url && <meta property="og:image" content={metaImage.url} />}
      <meta name="twitter:label1" content="Written by" />
      <meta name="twitter:data1" content={updatedBy} />
      {robotsContent && <meta name="robots" content={robotsContent} />}
      {schemaMarkup && (
        <script
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(schemaMarkup),
          }}
        />
      )}

      {/* Global metadata */}
      {/* TODO: use env variable for Open Graph URL and canonical URL */}
      <meta name="viewport" content="initial-scale=1, width=device-width" />
      <meta property="og:url" content={`${process.env.NEXT_PUBLIC_THD_DOMAIN}${router.asPath}`} />
      <link rel="canonical" href={`${process.env.NEXT_PUBLIC_THD_DOMAIN}${canonical || router.asPath}`} />
      {metadata?.tags.map(({ id, name, content, property }) => (
        <meta key={id} name={name || undefined} content={content || undefined} property={property || undefined} />
      ))}
    </Head>
  );
};

Meta.propTypes = {
  seo: shape({
    title: string,
    description: string,
    noIndex: bool,
    noFollow: bool,
    metaImage: shape({
      id: string,
      url: string,
    }),
  }),
  metadata: shape({ tags: arrayOf(shape({})) }),
  updatedBy: string,
};

Meta.defaultProps = {
  seo: {
    title: '',
    description: '',
    canonical: '',
    noIndex: false,
    noFollow: false,
    metaImage: {
      id: '',
      url: '',
    },
  },
  metadata: null,
  updatedBy: 'Harrison Stevens',
};

export default Meta;
